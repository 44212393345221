export class Api {
  static get(url) {
    return fetch(url, {method: 'GET'}).then(response => response.json())
  }
  static post(url, body) {
    return fetch(url, {method: 'POST', body}).then(response => response.json())
  }
  static put(url, body) {
    return fetch(url, {method: 'PUT', body}).then(response => response.json())
  }
  static del(url) {
    return fetch(url, {method: 'DELETE'}).then(response => response.json())
  }
}
