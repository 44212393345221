import React from 'react'
import {Page} from '../page'
import labelForm from './forms/label.json'
import LabelForm from './forms/label-form'

class LabelCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      templates: []
    }
  }

  render() {
    const {history} = this.props
    const dataConfig = JSON.parse(JSON.stringify(labelForm))
    const body = <LabelForm config={{dataConfig, history}}/>
    return <Page body={body} description='Create New Label' title='Create Label'/>
  }
}

export default LabelCreate
