import React from 'react'
import {Page} from '../page'
import labelForm from './forms/label.json'
import LabelForm from './forms/label-form'

class LabelEdit extends React.Component {
  constructor(props) {
    super(props)
    const extraOperation = this.props?.location?.state?.data?.extraOperation
    this.state = {
        id: this.props.location.state.data.id,
        externalcode: this.props.location.state.data.externalcode,
        profile: this.props.location.state.data.profile,
        quantity: this.props.location.state.data.quantity,
        colorcode: this.props.location.state.data.colorcode,
        color: this.props.location.state.data.color,
        punit: this.props.location.state.data.punit,
        munit: this.props.location.state.data.munit,
        coex: this.props.location.state.data.coex,
        brand: this.props.location.state.data.brand,
        chambers: this.props.location.state.data.chambers,
        extruder: this.props.location.state.data.extruder,
        basecolor: this.props.location.state.data.basecolor,
        gasket: this.props.location.state.data.gasket,
        template: this.props.location.state.data.template,
        zone: this.props.location.state.data.zone,
        warehouse: extraOperation?.warehouse,
        productCode: extraOperation?.productCode,
        client: extraOperation?.client
      }
  }

  render() {
    const data = this.state
    const {history} = this.props
    const dataConfig = JSON.parse(JSON.stringify(labelForm))
    for (const field of dataConfig.fields) {
      if (field.id === 'id') {
        field.formInput.disabled = true
      }
      field.formInput.value = data[field.id]
    }
    const body = <LabelForm config={{dataConfig, history}} data={data}/>
    return <Page body={body} description='Change Label Values' title='Edit Label'/>
  }
}

export default LabelEdit
