import prodConfig from '../../prod-config.json'
import devConfig from '../../dev-config.json'
import localConfig from '../../local-config.json'

export const getConfig = () => {
  let config
  switch (process.env.REACT_APP_STAGE){
    case 'production':
      config = prodConfig
      break
    case 'development':
      config = devConfig
      break
    case 'local':
      config = localConfig
      break
    default:
      config = devConfig
      break
  }
  return config
}
