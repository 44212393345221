import React from 'react'
import {FormInput, FormItem, Icon, InputGroup} from 'fundamental-react'

export const Search = ({onChange, placeholder, value}) => (
    <FormItem>
      <InputGroup>
        <InputGroup.Addon>
          <Icon glyph='search'/>
        </InputGroup.Addon>
        <FormInput id='term' value={value} type='text' placeholder={placeholder} onChange={onChange}/>
      </InputGroup>
    </FormItem>
)
