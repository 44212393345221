export const DEFAULT_NUMBER = 6.5
/**
 * Checked required values is not empty
 * @param {object} data
 * @return {string[]}
 */
export const validateLabelData = (data) => {
  let errors = []
  if(!data.id ||
    !data.template ||
    data.template === '-1' ||
    data.id.toString().trim() === ''){
    errors.push('Fill Required fields!!')
  }
  const dotLength = data.id && data.id.match(/\./g).length
  if(dotLength < 2 || dotLength > 3){
    errors.push('Invalid Code Format!!')
  }
  const extraOperation = [data.warehouse, data.client, data.productCode]
  if(!(extraOperation.every((el) => el) || extraOperation.every((el) => !el))){
    errors.push('Warehouse, Client and Product Code must have values!!')
  }
  return errors
}

/**
 * Transform invalid data
 * @param {object} data
 * @return {object}
 */
export const transformData = (data) => {
  for (const item of Object.keys(data)) {
    data[item] = data[item] === '' ? undefined : data[item]
  }
  const punit = (data.munit ?? DEFAULT_NUMBER) * (data.quantity ?? 0)
  data.punit = punit === 0 ? DEFAULT_NUMBER : punit
  data.extraOperation = {
    warehouse: data.warehouse,
    productCode: data.productCode,
    client: data.client
  }
  return data
}
