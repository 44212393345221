import React from 'react'
import {Dialog, Button, Link} from 'fundamental-react'

const ZERO_IMAGE_URL = 'https://pi-device-image.s3.eu-central-1.amazonaws.com/pi4-zero.img.gz'

export const Instructions = ({handleDialog, show}) => (
    <Dialog
      className={'custom-dialog'}
      show={show}
      title={'PiDevice Registration Instructions'}
      actions={[<Button glyph="sys-cancel" onClick={handleDialog}>
        Close
      </Button>]}
    >
      <div>
        <h4>Step 1:</h4>
        <p>
          Download this file.
        </p>
          <Link href={ZERO_IMAGE_URL}>
            Image
          </Link>
        <hr/>
        <h4>Step 2:</h4>
        <p>
          Take a new, 'clean' SD card. Make sure it is not in active use.
          <br/>
          Using Balena Etcher burn the file to the SD Card.
          This process must not be interrupted.
          <br/>
        </p>
        <hr/>
        <h4>Step 3:</h4>
        <p>
          Take a new device and make sure it is not in use or is clean.<br/>
          Disconnect it's power-cord.<br/>
          Connect said device to the internet by cable.
        </p>
        <hr/>
        <h4>Step 4:</h4>
        <p>
          Put the SD card into the device. <br/>
        </p>
        <hr/>
        <h4>Step 5: <b>IMPORTANT: Make sure all previous steps are complete!</b></h4>
        <p>
          Plug the power-cord to said device.
          Make sure it is on.
          <br/>
        </p>
        <hr/>
        <h4>Step 6:</h4>
        <p>
          Close this window and fill in form. Click "Register Device" when ready.
          <br/>
        </p>
      </div>
    </Dialog>
)
