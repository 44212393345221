import React from 'react'
import {Page} from '../page'
import {Button} from 'fundamental-react'
import {Search} from '../../forms/filters'
import RegistrationTable from './forms/registration-table'

class DevicesList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      term: ''
    }
  }

  componentDidMount = () => {
    this.props.subToNewDevices()
    this.props.subsEditDevice()
  }

  refreshPage = () => {
    window.location.reload(false)
  }

  updateSearch = (filter) => {
    this.setState({term: filter.target.value})
  }

  render() {
    const items = this.props.data?.getDevices?.items
    const term = this.state.term
    const history = this.props.history
    const refreshPage = <Button className='list-button' size='s' glyph='refresh' title='Refresh Page' onClick={this.refreshPage}/>
    const registerNewDevice = (
      <Button
        className='list-button'
        size='s'
        glyph='add'
        title='Register New Device'
        onClick={() => this.props.history.push('/registerDevice', {devices: items})}
      />
    )
    const table = <RegistrationTable history={history} term={term} data={items ?? []} />
    const search = <Search onChange={this.updateSearch} placeholder='Search Mac,Name,Img,Status ...'/>
    return (<Page body={table} actions={[registerNewDevice, refreshPage]} filters={search} title='Devices'
                    description='Devices List'/>)
  }
}

export default DevicesList
