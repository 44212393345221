import React from 'react'
import {Button} from 'fundamental-react'
import {Page} from '../components/pages/page'
import {getConfig} from './utils/config-utils'

const config = getConfig()
const url = `https://${config.appClinetDomain}.auth.${config.region}.amazoncognito.com/login?response_type=token&client_id=${config.userPoolAppClientId}&redirect_uri=${config.appUrl}`
const Login = () => {
  const body = <Button compact glyph="visits" onClick={() => window.location.replace(url)}>Login</Button>
  return (
    <Page
      body={body}
      title="Server Error"
      description="The server understood the request, but is refusing to authorize it."
    />
  )
}

export default Login
