import React from 'react'
import {Field} from './field'
import {FormFieldset} from 'fundamental-react'

export class Editor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handleFieldChange(fieldId, value) {
    this.setState({[fieldId]: value})
    this.props.onChange(fieldId, value)
  }

  render() {
    const fields = this.props.config.fields.map((field) => (
      <Field key={field.id} onChange={this.handleFieldChange} value={this.state[field.id]} {...field} />
    ))
    return(<FormFieldset>
      <b>{this.props.config.formName}</b>
      {fields}
    </FormFieldset>)
  }
}
