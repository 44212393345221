import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/app'
import {BrowserRouter as Router} from 'react-router-dom'
import {ApolloProvider} from 'react-apollo'
import AWSAppSyncClient, {AUTH_TYPE} from 'aws-appsync'
import {ThemeProvider} from '@ui5/webcomponents-react/lib/ThemeProvider'
import '@ui5/webcomponents/dist/Assets'
import '@ui5/webcomponents-fiori/dist/Assets'
import '@ui5/webcomponents-icons/dist/Assets.js'
import Auth from './auth/auth'
import {getConfig} from './auth/utils/config-utils'
import getCreds from './auth/utils/getCred'

getCreds()


const config = getConfig()

const client = new AWSAppSyncClient({
  url: config.graphqlEndpoint,
  region: config.region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: Auth.getToken()
  }
})

ReactDOM.render(
   <Router>
     <ApolloProvider client={client}>
       <ThemeProvider>
         <App />
       </ThemeProvider>
     </ApolloProvider>
   </Router>,
  document.getElementById('root')
)
