export const getDevices = `query GetDevices(
  $nextToken: String
) {
  getDevices(nextToken: $nextToken) {
    items {
      id
      name
      imgType
      country
      city
      location
      line
      macAddr
      status
      type
      statusDate
      formHash
      isCoExt
      coExt
      mainExt
    }
  }
}
`

export const getModelForm = `query GetSWModelForm{
  getSWModelForm{
    items{
      id
      label
      formInput{
        type
      }
    }
  }
}
`

export const getImages = `query GetImages( 
  $nextToken: String
) {
  getImages(nextToken: $nextToken) {
    items {
     imgType
     imgId
    }
  }
}
`
