import defaultDevice from '../forms/default-device.json'
import * as crypto from 'crypto'

export const validateData = (data) => {
  let errors = []
  const lineInput = parseInt(data.line)
  if (data.macAddr ? /^([\da-f]{2}[:]*){5}([\da-f]{2})$/.test(formatDeviceMac(data.macAddr)) === false : true)
    errors.push(`Device MAC ${data.macAddr} is invalid!\n`)
  if (Number.isNaN(lineInput) || lineInput < 1 || lineInput > 99) errors.push(`Device Line must be between 1 and 99!\n`)
  if (data.typeData === '') errors.push(`Device Type must be set!\n`)
  if (data.locationData === '') errors.push(`Device Location must be set!\n`)
  return errors
}

const getTypeNumber = (deviceData, devices) => {
  const {country, city, location, line, type} = deviceData
  const items = devices.filter(item => item.country === country &&
    item.city === city &&
    item.location === location &&
    item.type === type &&
    item.line === line)
  return String(items.length + 1).padStart(2, '0')
}

const formatDeviceMac = (macAddr) => {
  return macAddr
    .match(/[\dA-Za-z]{2}/g)
    .join(':')
    .toLowerCase()
}

export const transformData = (data) => {
  let deviceData = {}
  deviceData.country = data.locationData.split('-')[0]
  deviceData.city = data.locationData.split('-')[1].slice(0, 3)
  deviceData.location = data.locationData.split('-')[1].slice(3)
  deviceData.line = String(parseInt(data.line)).padStart(2, '0')
  deviceData.imgType = data.typeData

  deviceData.status = data.status ? data.status : defaultDevice.status
  deviceData.macAddr = formatDeviceMac(data.macAddr)
  deviceData.id = deviceData.macAddr
  deviceData.statusDate = new Date().toISOString()
  deviceData.type = defaultDevice.types[deviceData.imgType]
  deviceData.isCoExt = deviceData.type !== 'EXT' ? false : data.isCoExt
  const typeNumber = getTypeNumber(deviceData, data.devices)
  deviceData.name = `${deviceData.country}-${deviceData.city}${deviceData.location}-${deviceData.type}${typeNumber}-${deviceData.line}`
  return deviceData
}

export const hashValue = (value) => {
  return crypto.createHmac('sha256', value).digest('hex')
}
