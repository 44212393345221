import {getConfig} from './utils/config-utils'

const config = getConfig()
class Auth {
  // USERS
  static authenticateUser(token) {
    window.sessionStorage.setItem(config.sessName, token)
  }

  static isUserAuthenticated() {
    return window.sessionStorage.getItem(config.sessName) !== null
  }

  static getToken() {
    return window.sessionStorage.getItem(config.sessName)
  }

  static deAuthenticateUser() {
    window.sessionStorage.removeItem(config.sessName)
  }

  // ROLES
  static setRoles(roles) {
    window.sessionStorage.setItem(config.sessRole, roles)
  }

  static getRoles() {
    return window.sessionStorage.getItem(config.sessRole)
  }

  static haveRole(role) {
    return window.sessionStorage.getItem(config.sessRole)?.includes(role)
  }
}

export default Auth
