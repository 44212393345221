import React from 'react'
import {Route, Switch} from 'react-router-dom'
import AuthenticatedRoute from './authenticatedRoute'
import LabelsList from '../pages/labels/labels-list'
import Unauthorized from '../pages/unauthorized'
import {DeviceRegistration} from '../pages/registration/device-registration'
import LabelEdit from '../pages/labels/label-edit'
import LabelCreate from '../pages/labels/label-create'
import Devices from '../pages/registration/devices'
import {DeviceModel} from "../pages/registration/device-model";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {/*Labels*/}
        <AuthenticatedRoute exact role='mes-labels' path='/labels' component={LabelsList} />
        <AuthenticatedRoute exact role='mes-labels-create' path='/labels/create' component={LabelCreate} />
        <AuthenticatedRoute exact role='mes-labels-edit' path='/labels/edit' component={LabelEdit} />

        {/*Devices*/}
        <AuthenticatedRoute exact role='mes-registration' path='/' component={Devices} />
        <AuthenticatedRoute exact role='mes-registration' path='/device/model' component={DeviceModel} />
        <AuthenticatedRoute exact role='mes-registration' path='/registerDevice' component={DeviceRegistration} />

        <Route exact path='/unauthorized' component={Unauthorized} />
      </Switch>
    )
  }
}

export default Routes
