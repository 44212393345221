import React from 'react'
import {Editor} from '../../forms/editor'
import formConfig from './forms/registration-form.json'
import gql from 'graphql-tag'
import {Button, MessageStrip} from 'fundamental-react'
import {Page} from '../page'
import {registerDevice} from '../../graphql/mutations'
import {Mutation, Query} from 'react-apollo'
import defaultDevice from './forms/default-device.json'
import {Instructions} from './instructions'
import {validateData, transformData} from './utils/data-utils'
import {getImages} from '../../graphql/queries'

export class DeviceRegistration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      devices: this.props?.location?.state?.devices,
      errMsg: '',
      defaultDevice,
      locationData: ''
    }
  }

  handleSubmit = (event, registerDevice) => {
    const errs = validateData(this.state)
    if (errs.length > 0) {
      return this.setState({
        errMsg: errs
      })
    }

    const data = transformData(this.state)
    registerDevice({
      variables: {
        input: data
      }
    }).then(() => this.props.history.push('/'))
      .catch(err => console.error(err))
  }

  handleDialog = () => {
    this.setState({
      show: !this.state.show
    })
  }


  onChange = (fieldId, value) => {
    if(fieldId === 'isCoExt'){
      this.setState({[fieldId]: !this.state.coExt})
      return
    }
    this.setState({[fieldId]: value})
  }

  render() {
    const coExField = formConfig.fields.find(field => field.id === 'isCoExt')
    const deviceType = defaultDevice.types[this.state.typeData]
    const isExt = deviceType === 'EXT'
    isExt ? coExField.formInput.style  = {display: 'inline-block'} : coExField.formInput.style = {display: 'none'}
    return (
      <>
        {this.state.errMsg !== '' &&
        this.state.errMsg.map((err) => (
          <MessageStrip type='error'>
            {err}
          </MessageStrip>
        ))}
        <Query query={gql(getImages)}>
          {({loading, data, error}) => {
            if (loading) {
              return <h2>loading...</h2>
            }
            if (error) {
              return (
                <MessageStrip dismissible type='error'>
                  {error}
                </MessageStrip>
              )
            }
            if (data) {
              let images = data.getImages.items.map((image) => {
                return {
                  text: defaultDevice.types[image.imgType],
                  value: image.imgType
                }
              })
              formConfig.fields.find(field => field.id === 'typeData').formInput.options = images
            }
            const instructions = (
              <Button glyph='bookmark' onClick={this.handleDialog}>
                Instructions
              </Button>
            )
            const body = (
              <>
                <Mutation mutation={gql(registerDevice)}>
                  {(registerDevice, {loading, error}) => {
                    if (error) {
                      return (
                       <> <MessageStrip dismissible type='error'>
                          {error.message}
                        </MessageStrip>
                         <Button onClick={() => this.props.history.push('/')}>Back</Button>
                       </>
                      )
                    }
                    return (
                      <>
                        <Editor config={formConfig} onChange={this.onChange}/>
                        <Button option='transparent' onClick={() => this.props.history.push('/')}>Cancel</Button>
                        <Button
                          glyph='complete'
                          type='positive'
                          option='emphasized'
                          onClick={(event) =>
                            this.handleSubmit(event, registerDevice)}>{loading ? 'Creating...' : 'Register Device'}</Button>
                        {this.state.show && <Instructions show={this.state.show} handleDialog={this.handleDialog}/>}
                      </>
                    )
                  }}
                </Mutation>
              </>
            )
            return <Page body={body} actions={instructions} title='New Device' description='Registration'/>
          }}
        </Query>
      </>
    )
  }
}
