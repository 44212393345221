import React from 'react'
import {Table, Button} from 'fundamental-react'

class RegistrationTable extends React.Component{
  render() {
    let rows = []
    const searchTerm = this.props?.term?.trim().toLowerCase()
    this.props.data
      .filter((device) => device.macAddr.toLowerCase().indexOf(searchTerm) !== -1
        || device.name.toLowerCase().indexOf(searchTerm) !== -1
        || device.status.toLowerCase().indexOf(searchTerm) !== -1
        || device.imgType.toLowerCase().indexOf(searchTerm) !== -1)
      .forEach((device) => {
        rows.push({
            rowData: [
              device.type === 'EXT'
                ? <Button glyph='edit' title='Edit'  size='s' onClick={() => this.props.history.push(`/device/model`, device)}/>
                : '',
              device.macAddr,
              device.name,
              device.type  === 'EXT' && device.isCoExt ? 'EXT/CoExt' : device.type,
              device.imgType,
              device.line,
              device.country,
              device.city,
              device.location,
              device.status,
              device.statusDate,
              device.formHash
            ]
          }
        )
      })
    return  (<div className='custom-table'>
      <Table
        headers={[
          'Action',
          'Device MAC',
          'Device Name',
          'Device Type',
          'Image',
          'Line',
          'Country',
          'City',
          'Location',
          'Status',
          'Status Date',
          'Form Hash'
        ]}
        tableData={rows}
      />
    </div>)
  }

}

export default RegistrationTable
