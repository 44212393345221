import React from 'react'
import {AnalyticalTable, Button} from '@ui5/webcomponents-react'
import Auth from '../../../../auth/auth'

class LabelsTable extends React.Component{
  render() {
    const {
      labelsData,
      term,
      history} = this.props.data
    const data = labelsData
      .filter((data) =>
        data.id?.trim().toLowerCase().indexOf(term.trim().toLowerCase()) !== -1 ||
        data.profile?.trim().toLowerCase().indexOf(term.trim().toLowerCase()) !== -1 ||
        data.template?.trim().toLowerCase().indexOf(term.trim().toLowerCase()) !== -1)
    const columns = [{
      Header: 'Actions',
      sortable: false,
      filterable: false,
      Cell: val => (
        <div className='btn-group'>
          <Button className='list-button' icon='print' onClick={() => this.props.showDialog({id: val.row.original.id, quantity: val.row.original.quantity})} />
          {Auth.haveRole('mes-labels-edit') &&
          <Button className='list-button' icon='edit' onClick={() => history.push('/labels/edit', {data: val.row.original})} />}
        </div>
      ),
      disableResizing: true
    },
      {
        Header: 'Code',
        accessor: 'id',
        minWidth: '180',
        disableResizing: true
      },
      {
        Header: 'External Code',
        accessor: 'externalcode',
        disableResizing: true
      },
      {
        Header: 'Profile',
        accessor: 'profile',
        disableResizing: true
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        disableResizing: true
      },
      {
        Header: 'MUnit',
        accessor: 'munit',
        disableResizing: true
      },
      {
        Header: 'PUnit',
        accessor: 'punit',
        disableResizing: true
      },
      {
        Header: 'Brand',
        accessor: 'brand',
        disableResizing: true
      },
      {
        Header: 'Chambers',
        accessor: 'chambers',
        disableResizing: true
      },
      {
        Header: 'Extruder',
        accessor: 'extruder',
        disableResizing: true
      },
      {
        Header: 'Gasket',
        accessor: 'gasket',
        disableResizing: true
      },
      {
        Header: 'CoEx',
        accessor: 'coex',
        disableResizing: true
      },
      {
        Header: 'Base Color',
        accessor: 'basecolor',
        disableResizing: true
      },
      {
        Header: 'Color',
        accessor: 'color',
        disableResizing: true
      },
      {
        Header: 'Color Code',
        accessor: 'colorcode',
        disableResizing: true
      },
      {
        Header: 'Template',
        accessor: 'template',
        disableResizing: true
      },
      {
        Header: 'Zone',
        accessor: 'zone',
        disableResizing: true
      }]
    return (
      <AnalyticalTable
        columns={columns}
        data={data}
        noDataText={'No Data Found!!!'}
        scaleWidthMode={'Smart'}
        selectionMode={'None'}
        sortable={false}
        filterable={false}
        minRows={data.length}
      />)
  }
}

export default LabelsTable
