import React from 'react'
import printForm from './forms/print-dialog.json'
import {Button, Dialog, FormItem, MessageStrip} from 'fundamental-react'
import {Page} from '../page'
import {Editor} from '../../forms/editor'
import LabelsTable from './forms/labels-table'
import Auth from '../../../auth/auth'
import {Api} from './utils/fetch-utils'
import {Search} from '../../forms/filters'
import {getConfig} from '../../../auth/utils/config-utils'

const sessionTerm = 'labels-term'


const config = getConfig()

class LabelsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      labelsData: [],
      isoCode: '',
      quantity: '',
      printers: [],
      printer: '-1',
      cnt: 1,
      show: false,
      term: sessionStorage.getItem(sessionTerm) ?? '',
      dialogErrMsg: '',
      errMsg: ''
    }
  }

  componentDidMount() {
    Api.get(`${config.labelsBase}/labels`)
      .then((res) => {
        if (res?.errorMessage){
          this.setState({
            errMsg: res?.errorMessage
          })
          return
        }
        if (Array.isArray(res)) {
          this.setState({
            labelsData: res.filter(label => label)
          })
        }
      })

  }

  /**
   * Send Data for Print
   * @param event
   */
  onPrint = (event) => {
    event.preventDefault()
    this.setState({
      dialogErrMsg: ''
    })
    const {isoCode, printer, cnt, quantity} = this.state
    if (printer === '-1') {
      return
    }
    if(quantity <= 0 || !quantity){
      this.setState({dialogErrMsg: 'Quantity must be greater than 0 !!!'})
      return
    }
    Api.post(`${config.labelsBase}/print`, JSON.stringify({isoCode, cnt, quantity, printer}))
      .then((res) => {
        if (res.errorMessage || res.message) {
          this.setErrorState(res)
          return
        }
        this.showDialog()
      })
      .catch((err) => {
        this.setErrorState(err)
      })
  }

  /**
   * Handle input values from fields
   * @param fieldId
   * @param value
   */
  onChangeValue = (fieldId, value) => {
    if (fieldId?.target?.id === 'term') {
      const termValue = fieldId?.target?.value
      this.setState({
        term: termValue
      })
      sessionStorage.setItem(sessionTerm, termValue)
      return
    }
    this.setState({
      [fieldId]: value
    })
  }

  /**
   * Get active printers
   */
  getPrinters = () => {
    Api.get(`${config.labelsBase}/printers`)
      .then((res) => {
        if (Array.isArray(res)) {
          this.setState({
            printers: res
          })
        }
      }).catch((err) => {
      this.setErrorState(err)
    })
  }

  /**
   * Show/Hide Dialog box
   * @param data
   */
  showDialog = (data) => {
    this.setState({
      show: !this.state.show,
      isoCode: data?.id ?? '',
      quantity: data?.quantity,
      printer: '-1',
      cnt: 1,
      dialogErrMsg: ''
    })
    if (data) {
      this.getPrinters()
    }
  }

  /**
   * Set state to error msg
   * @param response
   */
  setErrorState = (response) => {
    this.setState({
      dialogErrMsg: response.errorMessage ?? response.message,
    })
  }

  render() {
    const {labelsData, term, printers, dialogErrMsg, errMsg} = this.state
    const printConfig = JSON.parse(JSON.stringify(printForm))
    for (const field of printConfig.fields) { // Add dynamic values to form
      if (field.id === 'printer') {
        for (const printer of printers) {
          field.formInput.options.push({
            text: printer,
            value: printer
          })
        }
      }
      field.formInput.value = this.state[field.id]
    }
    const body = (
      <>
        <Dialog
          actions={[
            <Button
              option='emphasized'
              title='Print Label'
              onClick={(e) => {
                this.onPrint(e)
              }}>Print</Button>,
            <Button
              option='transparent'
              title='Cancel'
              onClick={() => this.showDialog()}>Cancel</Button>
          ]}
          show={this.state.show}
          title='Print Label'
          size='s'>
          <Editor config={printConfig} onChange={this.onChangeValue}/>
          {dialogErrMsg !== '' && <MessageStrip dismissible type='error'>{dialogErrMsg}</MessageStrip>}
        </Dialog>
        {errMsg !== '' && <MessageStrip dismissible type='error'>{errMsg}</MessageStrip>}
        <LabelsTable showDialog={this.showDialog} data={{labelsData, term, history: this.props.history}}/>
      </>
    )
    const actions = (Auth.haveRole('mes-labels-create') &&
      <FormItem>
        <Button title='Create Label' glyph='add' onClick={() => this.props.history.push('/labels/create')}/>
      </FormItem>)

    const search = <Search value={term} onChange={this.onChangeValue} placeholder='Search Code, Profile, Template ...'/>

    return (<Page body={body} filters={search} actions={actions} title='Labels'
                  description='Choose product and print label'/>)
  }
}

export default LabelsList
