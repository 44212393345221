import React from 'react'
import {LayoutPanel} from 'fundamental-react'

export class Page extends React.Component {
  render() {
    return (
      <LayoutPanel>
        <LayoutPanel.Header>
          <LayoutPanel.Head title={this.props.title ?? ''} description={this.props.description ?? ''}
          />
          <LayoutPanel.Actions>{this.props.actions ?? ''}</LayoutPanel.Actions>
        </LayoutPanel.Header>
        {this.props.filters && <LayoutPanel.Filters>
          {this.props.filters}
          </LayoutPanel.Filters>}
        <LayoutPanel.Body>{this.props.body ?? ''}</LayoutPanel.Body>
      </LayoutPanel>
    )
  }
}
