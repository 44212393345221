export const onRegisterDevice = `subscription OnRegisterDevice {
  newDevice{
      id
      name
      imgType
      country
      city
      location
      line
      macAddr
      status
      type
      statusDate
      formHash
      isCoExt
      coExt
      mainExt
  }
}
`
export const onEditDevice = `subscription OnEditDevice {
  onEditDevice  {
    id
    name
    imgType
    country
    city
    location
    line
    macAddr
    status
    type
    statusDate
    formHash
    isCoExt
    coExt
    mainExt
  }
}
`
