import decode from 'jwt-decode'
import Auth from '../auth'
/**
 * Get parameter value from URL
 * @param {string} name Parameter name
 * @param {string} paramString URL
 */
const getParameterByName = (name, paramString) => {
  let searchParams = new URLSearchParams(paramString)
  return searchParams.has(name) ? searchParams.get(name) : ''
}

/**
 * Get credentials from url
 */
const getCreds = () => {
  let id_token = getParameterByName('id_token', window.location.hash.substring(1))
  if (id_token) {
    const decodeToken = decode(id_token)
    Auth.setRoles(decodeToken['custom:role'])
    Auth.authenticateUser(id_token)
    window.history.replaceState({}, document.title, '/')
  }
}

export default getCreds
