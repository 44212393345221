export const registerDevice = `mutation RegisterDevice(
    $input: CreateDeviceInput!
){
registerDevice(input: $input) {
    id
    name
    imgType
    country
    city
    location
    line
    macAddr
    status
    type
    statusDate
    isCoExt
    coExt
    mainExt
  }
}
`

export const editDevice = `mutation EditDevice($input: EditDeviceInput!) {
  editDevice(input: $input) {
    id
    name
    imgType
    country
    city
    location
    line
    macAddr
    status
    type
    statusDate
    formHash
    isCoExt
    coExt
    mainExt
  }
}
`
