import React from 'react'
import gql from 'graphql-tag'
import {editDevice} from '../../graphql/mutations'
import {Button, LayoutGrid, LayoutPanel} from 'fundamental-react'
import {Editor} from '../../forms/editor'
import {Mutation, Query} from 'react-apollo'
import {Page} from '../page'
import {getModelForm} from '../../graphql/queries'
import {hashValue} from './utils/data-utils'

export class DeviceModel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mainExt: '',
      coExt: '',
      isCoExt: this.props.location?.state?.isCoExt
    }
  }

  componentDidMount() {
    if (this.props?.location?.state?.mainExt) {
      this.setState({mainExt: JSON.parse(this.props.location.state.mainExt)})
    }
    if (this.props?.location?.state?.coExt) {
      this.setState({coExt: JSON.parse(this.props.location.state.coExt)})
    }
  }

  handleSubmit = (event, editDevice, form) => {
    const input = this.props.location.state
    input.mainExt = JSON.stringify(this.state.mainExt)
    input.coExt = this.state.isCoExt ? JSON.stringify(this.state.coExt) : undefined
    input.formHash = hashValue(JSON.stringify(form))
    delete input['__typename']
    editDevice({
      variables: {
        input: input
      }
    }).then(() => this.props.history.push('/'))
      .catch(err => console.error(err))
  }

  onChange = (fieldId, value, ext) => {
    if (ext) {
      this.setState({mainExt: {...this.state.mainExt, [fieldId]: value}})
      return
    }
    this.setState({coExt: {...this.state.coExt, [fieldId]: value}})
  }

  generateForm = (formName, form) => {
    return {
      formName: formName,
      formDescription: formName,
      fields: JSON.parse(JSON.stringify(form))
    }
  }

  render() {
    const {isCoExt} = this.state
    const extData = this.state.mainExt
    const coExtData = this.state.coExt

    return (<Query query={gql(getModelForm)}>
      {({loading, data, error}) => {
        if (loading) {
          return <h2>loading...</h2>
        }
        if (data?.getSWModelForm?.items) {
          const form = data.getSWModelForm.items
          let extConfig = {}
          let coExtConfig = {}

          extConfig = this.generateForm('EXT Model', form)
          extConfig.fields.map(field => {
            return field.formInput.value = extData[field.id]
          })
          if(isCoExt){
            coExtConfig = this.generateForm('CoExt Model', form)
            coExtConfig.fields.map(field => {
              return field.formInput.value = coExtData[field.id]
            })
          }
          const body = (<><Mutation mutation={gql(editDevice)}>
            {(editDevice, {loading, error}) => {
              if (error) {
                return <h2>{error}</h2>
              }
              return (<>
                <LayoutGrid cols={isCoExt ? 2 : 1}>
                  <LayoutPanel>
                    <Editor config={extConfig} onChange={(fieldId, value) => this.onChange(fieldId, value, true)}/>
                  </LayoutPanel>
                  {isCoExt && <LayoutPanel>
                    <Editor config={coExtConfig} onChange={this.onChange}/>
                  </LayoutPanel>}
                </LayoutGrid>
                <Button option='transparent' onClick={() => this.props.history.push('/')}>Cancel</Button>
                <Button
                  glyph='complete'
                  type='positive'
                  option='emphasized'
                  onClick={(event) =>
                    this.handleSubmit(event, editDevice, form)}>{loading ? 'Set...' : 'Set Model'}</Button>
              </>)
            }}
          </Mutation></>)
          return <Page body={body} title='Set Model' description='Set SiteWise Model Values'/>
        }
        return <h2>{error}</h2>
      }}
    </Query>)
  }
}
