import React from 'react'
import {FormItem, FormInput, FormLabel, FormSelect, Checkbox} from 'fundamental-react'

export class Field extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    const value = event.target.value
    this.props.onChange(this.props.id, value)
  }

  render() {
    let formInputType = this.props.formInput?.type ?? 'Text'
    let formInput = ''
    if (formInputType === 'Text' || formInputType === 'Number') {
      formInput = <FormInput onChange={this.handleChange} {...this.props.formInput} />
    }

    if(formInputType === 'Checkbox'){
      formInput = <Checkbox onChange={this.handleChange} {...this.props.formInput}>{this.props.name}</Checkbox>
    }

    if (formInputType === 'Select') {
      const options = this.props.formInput.options ?? []

      const list = options.map((option) => (
        <option value={option.value ?? option.text} key={option.value ?? option.text}>
          {option.text ?? option.value}
        </option>
      ))
      formInput = (
        <FormSelect onChange={this.handleChange} {...this.props.formInput}>
          <option value="-1">...</option>
          {list}
        </FormSelect>
      )
    }
    return (
      <FormItem className={this.props.className}>
        <FormLabel htmlFor={this.props.id} required={this.props.formInput?.required ?? false}>
          {this.props.label ?? this.props.id}
        </FormLabel>
        {formInput}
      </FormItem>
    )
  }
}
