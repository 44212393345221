import React from 'react'
import {Button, FormItem, LayoutGrid, LayoutPanel, MessageStrip} from 'fundamental-react'
import {Editor} from '../../../forms/editor'
import {FileUploader} from '@ui5/webcomponents-react/lib/FileUploader'
import {getConfig} from '../../../../auth/utils/config-utils'
import {Api} from '../utils/fetch-utils'
import {validateLabelData, DEFAULT_NUMBER, transformData} from '../utils/data-utils'

const config = getConfig()

class LabelForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        id: this.props?.data?.id,
        externalcode: this.props?.data?.externalcode,
        profile: this.props?.data?.profile,
        brand: this.props?.data?.brand,
        chambers: this.props?.data?.chambers,
        extruder: this.props?.data?.extruder,
        gasket: this.props?.data?.gasket,
        quantity: this.props?.data?.quantity,
        colorcode: this.props?.data?.colorcode,
        basecolor: this.props?.data?.basecolor,
        color: this.props?.data?.color,
        punit: this.props?.data?.punit,
        coex: this.props?.data?.coex,
        munit: this.props?.data?.munit,
        template: this.props?.data?.template,
        zone: this.props?.data?.zone,
        warehouse: this.props?.data?.warehouse,
        productCode: this.props?.data?.productCode,
        client: this.props?.data?.client
      },
      img: null,
      imgName: '',
      imgView: '',
      imgFromUpload: null,
      errMsg: '',
      templates: []
    }
  }

  componentDidMount() {
    if (this.state.data.id) {
      this.getImg()
    }
    this.getTemplate()
  }

  /**
   * Get Product zpl template
   */
  getTemplate = () => {
    Api.get(`${config.labelsBase}/templates`).then((res) => {
      if (Array.isArray(res)) {
        this.setState({
          templates: res
        })
      }
    })
  }
  /**
   * Get Product Img
   */
  getImg = () => {
    Api.get(`${config.labelsBase}/img/${this.state.data.id}`)
      .then(res => {
        if (!res.errorMessage) {
          this.setState({
            img: res,
            imgView: btoa(String.fromCharCode.apply(null, res.data))
          })
        }
      })
      .catch(err => {
        this.setErrorState(err)
      })
  }

  /**
   * Handle input values from fields
   * @param fieldId
   * @param value
   */
  onChange = (fieldId, value) => {
    this.setState({data: {...this.state.data, [fieldId]: value}})
  }

  /**
   * Save Data
   */
  handleSubmit = () => {
    this.setState({errMsg: ''})
    const errors = validateLabelData(this.state.data)
    if (errors.length > 0) {
      return this.setState({
        errMsg: errors.join('! ')
      })
    }
    const data = transformData(this.state.data)
    Api.post(`${config.labelsBase}/label`, JSON.stringify({data, image: this.state.img}))
      .then(res => {
        if (res) {
          this.setErrorState(res)
          return
        }
        this.props.config.history.push('/labels')
      })
      .catch((err) => {
        this.setErrorState(err)
      })
  }

  /**
   * Upload File
   * @param event
   */
  onUploadFile = (event) => {
    const dataFile = event.detail.files[0]
    const reader = new FileReader()
    reader.onload = () => {
      const data = Buffer.from(reader.result)
      this.setState({
        img: data,
        imgName: dataFile.name,
        imgFromUpload: URL.createObjectURL(dataFile)
      })
    }
    reader.readAsArrayBuffer(dataFile)
  }

  /**
   * Set state to error msg
   * @param response
   */
  setErrorState = (response) => {
    this.setState({
      errMsg: response.errorMessage ?? response.message
    })
  }

  render() {
    const {imgName, imgFromUpload, imgView, errMsg, templates} = this.state
    const {dataConfig, history} = this.props.config
    dataConfig.fields.forEach(field => {
      if (field.id === 'template') {
        field.formInput.options = []
        field.formInput.value = this.state.data.template
        for (const template of templates) {
          field.formInput.options.push({
            text: template,
            value: template
          })
        }
        return
      }
      if (field.id === 'punit') {
        return field.formInput.value = this.state.data.quantity * (this.state.data.munit ?? DEFAULT_NUMBER)
      }
      return (field.formInput.value = this.state.data[field.id])
    })
    return (
      <LayoutGrid cols={6} nogap>
        <LayoutPanel colSpan={3}>
          <LayoutPanel.Body>
            <Editor config={dataConfig} onChange={this.onChange}/>
            {errMsg !== '' && <MessageStrip type="error">{errMsg}</MessageStrip>}
            <FormItem>
              <div className="action-buttons">
                <Button className="list-button" title="Cancel" onClick={() => history.push('/labels')}>
                  Cancel
                </Button>
                <Button className="list-button" title="Save Data" option="emphasized" onClick={this.handleSubmit}>
                  Save
                </Button>
                <div id='file-reader'>
                  <FileUploader
                    disabled={false}
                    hideInput={true}
                    multiple={false}
                    valueState="None"
                    accept={'.png'}
                    onChange={this.onUploadFile}
                  >
                    <Button title='Upload File' className='file-reader' glyph='upload'>Change Image</Button>
                  </FileUploader>
                </div>
              </div>
            </FormItem>
          </LayoutPanel.Body>
        </LayoutPanel>
        <LayoutPanel colSpan={3}>
          <LayoutPanel.Body>
            <FormItem>
              <div id='label-img-div'>
                <img alt='No Content' src={imgFromUpload ?? `data:image/png;base64,${imgView}`} id='label-image'/>
                <p>{imgName}</p>
              </div>
            </FormItem>
          </LayoutPanel.Body>
        </LayoutPanel>
      </LayoutGrid>
    )
  }
}

export default LabelForm
