import React from 'react'
import {Page} from './page'

class Unauthorized extends React.Component {
  render() {
    const body = (
      <div>
        <h3>Talk with your administrator!!!</h3>
      </div>
    )
    return <Page body={body} title='Unauthorized' description='Don`t have permissions to open this page' />
  }
}
export default Unauthorized
