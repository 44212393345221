import React from 'react'
import {Query} from 'react-apollo'
import gql from 'graphql-tag'
import {getDevices} from '../../graphql/queries'
import {MessageStrip} from 'fundamental-react'
import DevicesList from './devices-list'
import {onEditDevice, onRegisterDevice} from '../../graphql/subscriptions'

class Devices extends React.Component{

  subToNewDevices = (subscribeToMore) => {
    return subscribeToMore({
      document: gql(onRegisterDevice),
      updateQuery: (prev, {subscriptionData}) => {
        if (!subscriptionData.data) return prev
        const newDeviceData = subscriptionData.data.newDevice
        return Object.assign({}, prev, {
          getDevices: {
            ...prev.getDevices,
            items: [...prev.getDevices.items, newDeviceData]
          }
        })
      }
    })
  }

  subsEditDevice = (subscribeToMore) => {
    return subscribeToMore({
      document: gql(onEditDevice),
      updateQuery: (previousResult, {subscriptionData}) => {
        const editDevice = subscriptionData.data.onEditDevice
        if (previousResult.getDevices.items.find((d) => d.id === editDevice.id)) {
          return Object.assign({}, previousResult, {
            getDevices: {
              ...previousResult.getDevices,
              items: [...previousResult.getDevices.items]
            }
          })
        } else {
          return previousResult
        }
      }
    })
  }

  render() {
    return (
        <Query query={gql(getDevices)} fetchPolicy='cache-and-network'>
          {({loading, data, error, subscribeToMore}) => {
            return (
              <>
                {error && <MessageStrip type="error">{error.message}</MessageStrip>}
                <DevicesList
                  data={data}
                  history={this.props.history}
                  subToNewDevices={() => this.subToNewDevices(subscribeToMore)}
                  subsEditDevice={() => this.subsEditDevice(subscribeToMore)}
                />
              </>
            )
          }}
        </Query>
    )
  }
}

export default Devices
