import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import Auth from '../../auth/auth'

const AuthenticatedRoute = ({component: Component, role}) => (
  <Route
    render={(props) =>
      Auth.isUserAuthenticated() && Auth.haveRole(role) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/unauthorized',
            state: {from: props.location}
          }}
        />
      )
    }
  />
)

export default AuthenticatedRoute
