import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import {Shellbar} from 'fundamental-react'
import Auth from '../auth/auth'
import user from '../assets/user.png'
import Routes from './routes/routes'
import Login from '../auth/login'
import getCreds from '../auth/utils/getCred'
import './app.css'

class App extends React.Component {
  constructor(props) {
    super(props)
    getCreds()
  }

  render() {
    const {history} = this.props
    return (
        <div className='App'>
          <Shellbar
            logo={<img alt='PROFILINK' src='./logo.png' />}
            productTitle='Portal'
            productMenu={
              Auth.isUserAuthenticated() && [
                Auth.haveRole('mes-registration') && {
                  callback: () => history.push('/'),
                  name: 'Devices'
                },
                Auth.haveRole('mes-labels') && {
                  callback: () => history.push('/labels'),
                  name: 'Labels'
                }
              ]
            }
            profile={
              Auth.isUserAuthenticated() && {
                colorAccent: 8,
                image: user
              }
            }
            profileMenu={[
              {
                callback: () => {
                  Auth.deAuthenticateUser()
                  history.push('/')
                },
                glyph: 'log',
                name: 'Sign Out',
                size: 's'
              }
            ]}
          />
          {Auth.isUserAuthenticated() ? <Routes /> : <Login />}
          {Auth.isUserAuthenticated() && Auth.haveRole('mes-labels') && <Redirect to='/labels' />}
          {Auth.isUserAuthenticated() && Auth.haveRole('mes-registration') && <Redirect to='/' />}
        </div>
    )
  }
}

export default withRouter(App)
